<template>
  <v-col lg="6">
    <v-card class="mt-5">
      <v-card-title>Login</v-card-title>
      <v-card-text>
      <v-form>
        
          <v-text-field
            id="email-input"
            v-model="form.email"
            type="email"
            required
            label="Email"
            placeholder="Enter email"
          ></v-text-field>

          <validation :error="getErrors" error-key="email" />
    
          <v-text-field
            id="password-input"
            v-model="form.password"
            type="password"
            @keyup.enter="login"
            required
            label="Password"
            placeholder="Enter password"
          ></v-text-field>

          <validation :error="getErrors" error-key="password" />
        
      </v-form>
      </v-card-text>
     
        <v-btn block
          color="primary"
          :loading="getLoading"
          @click="login"
        >Login</v-btn>
         <v-btn block
          color="primary"
          class="mt-3"
          text
          @click="register"
        >Register</v-btn>
      
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      form: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapGetters(["getLoading", "getErrors"])
  },
  methods: {
    login() {
      this.$store.dispatch("login", this.form);
    },
     register() {
      this.$router.push("register");
    }
  }
};
</script>
